import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import SignInForm from "components/modules/auth/SignInForm";
import AuthSimpleLayout from "layouts/AuthSimpleLayout";
import { useAuth } from "providers/AuthProvider";
import { Alert, Form } from "react-bootstrap";
import { Navigate, useSearchParams } from "react-router-dom";

const postLogin = async (data: any) => {
  return await axios.post("/api/account/login", data);
};

const SignIn = () => {
  const { setNewPassword, setUser } = useAuth();
  const [searchParams] = useSearchParams();
  const { isPending, mutate, error, data } = useMutation<
    {
      data: {
        sucesso: number;
        usuario: { email: string; nome: string; roles: string[] };
      };
    },
    any,
    { email: string; senha: string }
  >({
    mutationFn: postLogin,
  });

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await mutate({
      email: e.currentTarget.email.value,
      senha: e.currentTarget.password.value,
    });
  };

  // if (data?.data?.status === 2) {
  //   console.log(data);
  //   setNewPassword(data.data.email, data.data.session);
  //   return (
  //     <Navigate
  //       to="/pages/authentication/simple/reset-password"
  //       replace
  //       state={{
  //         email: data.data.email,
  //         session: data.data.session,
  //       }}
  //     />
  //   );
  // }

  // if (data?.data?.status === 1) {
  //   return (
  //     <Navigate
  //       to="/pages/authentication/simple/2FA"
  //       replace
  //       state={{ email: data.data.email, recovery: false }}
  //     />
  //   );
  // }

  if (data?.data?.usuario && data?.data?.sucesso) {
    setUser({
      email: data.data.usuario.email,
      name: data.data.usuario.nome,
      roles: data.data.usuario.roles,
    });
    const from = searchParams.get("from");
    return <Navigate to={from || "/"} replace />;
  }

  return (
    <AuthSimpleLayout>
      <Form onSubmit={handleLogin}>
        <SignInForm layout="simple" loading={isPending} />
        {error && (
          <Alert variant={"phoenix-danger"} className="mt-4">
            {error?.response?.data?.mensagem || "Ocorreu um erro inesperado."}
          </Alert>
        )}
      </Form>
    </AuthSimpleLayout>
  );
};

export default SignIn;

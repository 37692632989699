import { useAuth } from "providers/AuthProvider";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element }: { element: JSX.Element }) => {
  const { user } = useAuth();

  if (!user) {
    // Se o usuário não estiver autenticado, redirecione para a página de login
    return <Navigate to="/pages/authentication/simple/sign-in" replace />;
  }

  // if (user?.newPasswordRequired) {
  //   // Se o usuário estiver autenticado, mas precisar de uma nova senha, redirecione para a página de nova senha
  //   return (
  //     <Navigate to="/pages/authentication/simple/reset-password" replace />
  //   );
  // }

  // if (user?.twoFactorRequired) {
  //   // Se o usuário estiver autenticado, mas precisar de 2FA, redirecione para a página de 2FA
  //   return <Navigate to="/pages/authentication/simple/2FA" replace />;
  // }

  // Se estiver autenticado, renderize o componente passado
  return element;
};

export default ProtectedRoute;
